import { Button, Link } from '@chakra-ui/react';
import type { ButtonProps as ChakraButtonProps } from '@chakra-ui/react';

export interface ButtonProps extends ChakraButtonProps {
  theme?: {
    green100?: string;
    green200?: string;
    green300?: string;
    blue100?: string;
    blue200?: string;
    blue300?: string;
    yellow100?: string;
    yellow200?: string;
    yellow300?: string;
    red100?: string;
  };
  href?: string;
}

export const PrimaryButton = ({ theme, ...props }: ButtonProps) => {
  const ButtonType = (props) => {
    if (props.href) {
      return (
        <Link
          href={props.href}
          display="inline-flex"
          alignItems="center"
          justifyContent="center"
          {...props}
          textDecoration="none"
        >
          {props.children}
        </Link>
      );
    } else {
      return <Button {...props}>{props.children}</Button>;
    }
  };

  return (
    <ButtonType
      fontWeight="bold"
      color="white"
      bgColor={theme.green200}
      minW="32"
      h="12"
      borderRadius="0.25rem"
      _hover={{
        backgroundColor: theme.green300,
        boxShadow: '0 0.25rem 0.5rem rgba(0, 0, 0, 0.12)',
      }}
      transition="all 0.3s ease-in-out"
      textDecoration="none"
      {...props}
    >
      {props.children}
    </ButtonType>
  );
};

export const OutlineButton = ({ ...props }) => {
  return (
    <PrimaryButton
      color={props.theme.green200}
      bgColor="transparent"
      border={`1px solid ${props.theme.green200}`}
      _hover={{
        backgroundColor: props.theme.green200,
        color: 'white',
        boxShadow: '0 0.25rem 0.5rem rgba(0, 0, 0, 0.12)',
      }}
      {...props}
    >
      {props.children}
    </PrimaryButton>
  );
};
