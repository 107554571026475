import { MultilinkStoryblok } from './types';

export function generateStoryblokLinkUrl(link: MultilinkStoryblok) {
  if (!link) return null;

  if (link.linktype === 'story') {
    return `/${link.url || link.cached_url}${link.anchor || ''}`;
  }

  return link.url || link.cached_url;
}
