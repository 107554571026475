import { ReactNode, useEffect } from 'react';
import Head from 'next/head';
import Cookies from 'js-cookie';

import favicon from '../public/favicon.ico';
import { IntercomPerformanceOptimized } from '../lib/intercom';

import Header from './Header/Header';
import Footer from './Footer/Footer';
import { useSegment } from '@lib/segment/SegmentContext';

export default function Layout({
  children,
  type,
  footer,
  getStartedLink,
  hideTopFooter,
  hideBanner,
  withCTA,
}: {
  children: ReactNode;
  type: 'businesses' | 'engineers';
  footer?: {
    longText: boolean;
    text: string;
    image?: string;
    button?: {
      url: string;
      text: string;
    };
    outlineButton?: {
      url: string;
      text: string;
    };
  };
  withCTA?: boolean;
  getStartedLink?: string;
  hideTopFooter?: boolean;
  hideBanner?: boolean;
}) {
  const segment = useSegment();

  useEffect(() => {
    if (segment.canTrackUser) {
      segment.trackPage({
        category: type,
        name: document.title,
      });
    }
  }, [segment, type]);

  // for Engineers redirect set cookie so on home page it will not redirect
  // expires in 1 hour

  !Cookies.get('nextCheckedLocation') &&
    Cookies.set('nextCheckedLocation', true, { expires: 1 / 24 });

  if (type === 'engineers') {
    return (
      <>
        <Head>
          <link rel="shortcut icon" href={favicon.src} />
        </Head>
        <Header type={type} getStartedLink={getStartedLink} hideBanner={hideBanner} />
        <IntercomPerformanceOptimized type={type} />
        {children}
        {footer ? (
          <Footer type={type} footer={footer} withCTA={withCTA} hideTopFooter={hideTopFooter} />
        ) : null}
      </>
    );
  }

  return (
    <>
      <Head>
        <link rel="shortcut icon" href={favicon.src} />
      </Head>
      <Header type={type} getStartedLink={getStartedLink} hideBanner={hideBanner} />
      {children}

      {footer ? (
        <Footer type={type} footer={footer} withCTA={withCTA} hideTopFooter={hideTopFooter} />
      ) : null}
    </>
  );
}
