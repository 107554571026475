import { StoryblokComponent } from '@storyblok/react';
import type { GlobalComponentStoryblok, Storyblok } from '../types';
import { ExitPreviewMode } from '../ExitPreviewMode';

export function GlobalComponent({ blok, page }: Storyblok<GlobalComponentStoryblok>) {
  // TODO: https://www.storyblok.com/tp/using-relationship-resolving-to-include-other-content-entries#resolving-more-than-50-relations
  if (typeof blok.story === 'string' || !blok.story) return null;
  return (
    <>
      <ExitPreviewMode preview={page.preview} currentSlug={page.full_slug} />
      <StoryblokComponent key={blok.story?.uuid} blok={blok.story.content} page={page} />;
    </>
  );
}
