import { StoryblokComponent } from '@storyblok/react';
import type { EmptyPageStoryblok, Storyblok } from '../types';
import { ExitPreviewMode } from '../ExitPreviewMode';

export function EmptyPage({ blok, page }: Storyblok<EmptyPageStoryblok>) {
  return (
    <>
      <ExitPreviewMode preview={page.preview} currentSlug={page.full_slug} />
      {blok.body?.map((nestedBlok) => (
        <StoryblokComponent blok={nestedBlok} page={page} key={nestedBlok._uid} />
      ))}
    </>
  );
}
