import '../styles/globals.css';

import { useEffect } from 'react';
import { ErrorBoundary } from '@sentry/nextjs';
import { storyblokInit, apiPlugin } from '@storyblok/react';
import {
  Banner,
  Button,
  CandidateCardsSection,
  CandidatesAndHiringProcess,
  Card,
  CaseStudyCard,
  CustomList,
  DecorativeColorBoxes,
  DemandPage,
  DeveloperStoryCard,
  EmptyPage,
  EngineeringJobBoard,
  FAQSection,
  FaqWithoutSidebar,
  FeatureDescriptionCard,
  Footer,
  FooterSection,
  Form,
  GlobalComponent,
  Grid,
  Heading,
  HeroWithAnimatedText,
  HeroWithBGImage,
  HireDevelopersContent,
  HiringPlansHero,
  JobCard,
  JobDescriptionList,
  RichText,
  RichTextWithCopy,
  RolesList,
  RolesPage,
  SimpleSpotlight,
  SkillsList,
  SkillsPage,
  Slider,
  Spacer,
  Spotlight,
  SpotlightContent,
  Stack,
  StoryblokImage,
  SupplyPage,
  SVGAnimation,
  Table,
  TabsWithContent,
  TestimonialSection,
  TestimonialsSection,
  VerticalCarousel,
  Youtube,
  DownloadFormSection,
} from '@components/storyblok';

import { ChakraProvider } from '@chakra-ui/react';
import { Outfit } from 'next/font/google';
import { ApolloProvider } from '@apollo/client';
import NextNprogress from 'nextjs-progressbar';
import { useRouter } from 'next/router';
import { SegmentProvider } from '@lib/segment/SegmentContext';

import { useApollo } from '../apollo-client';
import theme from '../styles/theme';
import { appWithTranslation } from 'next-i18next';
import { useSetUtmCookies } from '../hooks/utmCookies';

import { AppProps } from 'next/app';
import getConfig from 'next/config';
import ErrorFallback from '@_pages/ErrorFallback';
import { Text } from '@components/storyblok/primitive/Text';

const { serverRuntimeConfig, publicRuntimeConfig } = getConfig();

storyblokInit({
  accessToken: serverRuntimeConfig.storyblokApiToken || publicRuntimeConfig.storyblokApiToken,
  use: [apiPlugin],
  apiOptions: {
    region: serverRuntimeConfig.storyblokRegion || publicRuntimeConfig.storyblokRegion,
  },
  components: {
    /**
     * @ Content types
     */
    emptyPage: EmptyPage,
    demandPage: DemandPage,
    skillsPage: SkillsPage,
    rolesPage: RolesPage,
    supplyPage: SupplyPage,

    globalComponent: GlobalComponent,

    /**
     * @ Primitive components
     */
    button: Button,
    customList: CustomList,
    grid: Grid,
    image: StoryblokImage,
    richText: RichText,
    slider: Slider,
    stack: Stack,
    spacer: Spacer,
    youtube: Youtube,
    card: Card,
    decorativeColorBoxes: DecorativeColorBoxes,
    verticalCarousel: VerticalCarousel,
    heading: Heading,
    text: Text,
    simpleTable: Table,
    footerSection: FooterSection,

    /**
     * @ Complex components
     */
    banner: Banner,
    caseStudyCard: CaseStudyCard,
    developerStoryCard: DeveloperStoryCard,
    faqWithoutSidebar: FaqWithoutSidebar,
    featureDescriptionCard: FeatureDescriptionCard,
    heroWithAnimatedText: HeroWithAnimatedText,
    heroWithBGImage: HeroWithBGImage,
    spotlight: Spotlight,
    testimonialsSection: TestimonialsSection,
    candidatesAndHiringProcess: CandidatesAndHiringProcess,
    faqSection: FAQSection,
    testimonialSection: TestimonialSection,
    downloadFormSection: DownloadFormSection,

    hireDevelopersContent: HireDevelopersContent,
    spotLightContent: SpotlightContent,
    candidateCardsSection: CandidateCardsSection,
    svgAnimation: SVGAnimation,
    simpleSpotlight: SimpleSpotlight,
    engineeringJobBoard: EngineeringJobBoard,
    richTextWithCopy: RichTextWithCopy,
    footer: Footer,
    tabsWithContent: TabsWithContent,

    /**
     * @ Static Components
     */
    skillsList: SkillsList,
    rolesList: RolesList,
    hiringPlansHero: HiringPlansHero,
    form: Form,
    jobDescriptionList: JobDescriptionList,
    jobCard: JobCard,

    /**
     * @ Development Components
     */

    // devYash: AnimatedText,
  },
});

const outfit = Outfit({ subsets: ['latin'] });

function MyApp({ Component, pageProps }: AppProps) {
  const apolloClient = useApollo(pageProps);
  const router = useRouter();

  /*  We have people coming from Iterable, but they're appearing as anonymous users. We want to identify them so that we can see Email Link Clicked and
      Loaded a Page event on a single profile in Mixpanel. We're using email address as the identifier */
  useEffect(() => {
    if ((window as any).analytics) {
      const trackingId = router.query.tracking_id;

      if (trackingId) {
        // Segment's identity resolution will only work if we identify a user with a matching trait.
        const email = Array.isArray(trackingId) ? '' : decodeURIComponent(trackingId);
        if (email.match(/.+@.+/)) {
          (window as any).analytics.identify({ email: email.toLowerCase() });
        }

        // Mixpanel will not automatically merge anonymous users when we identify them by email, so
        // we need to call alias() to merge the front-end and backend profiles.
        (window as any).analytics.alias(
          Array.isArray(trackingId) ? trackingId : decodeURIComponent(trackingId),
        );
      }
    }
  }, [router.query.tracking_id]);

  useSetUtmCookies();

  return (
    <>
      {/* eslint-disable-next-line react/no-unknown-property */}
      <style jsx global>
        {`
          :root {
            --font-outfit: ${outfit.style.fontFamily};
          }

          html,
          body {
            font-family: ${outfit.style.fontFamily};
          }
        `}
      </style>
      <ChakraProvider theme={theme} cssVarsRoot="body">
        <ErrorBoundary fallback={({ resetError }) => <ErrorFallback resetError={resetError} />}>
          <NextNprogress
            color="#25383F"
            startPosition={0.3}
            stopDelayMs={200}
            height={3}
            showOnShallow={true}
            options={{
              showSpinner: false,
            }}
          />
          <ApolloProvider client={apolloClient}>
            <SegmentProvider>
              <Component {...pageProps} />
            </SegmentProvider>
          </ApolloProvider>
        </ErrorBoundary>
      </ChakraProvider>
    </>
  );
}

export default appWithTranslation(MyApp);
