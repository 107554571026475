import Layout from '@components/Layout';
import { selectMetaRobots_forStoryblok } from '@helpers/metaRobots';
import sitePath from '@helpers/sitePath';
import { returnPathWithStartingSlash } from '@helpers/storyblok.utils';
import { storyblokEditable, StoryblokComponent } from '@storyblok/react';
import Head from 'next/head';
import sharecardImage from '@public/images/sharecard.jpg';
import type { Storyblok, SupplyPageStoryblok } from '../types';
import { ExitPreviewMode } from '../ExitPreviewMode';

const footerCTA = {
  longText: true,
  text: 'Sign up for Terminal to view our latest job postings now',
};

export function SupplyPage({ blok, page }: Storyblok<SupplyPageStoryblok>) {
  const additionalSEO = blok.AdditionalSEO?.find((eachSEO) => eachSEO.component === 'seo');
  const metaTitle = [blok.SEO?.title || page.name, 'terminal.io'].join(' - ');
  const metaDescription = blok.SEO?.description;
  const { footer } = page.extra;

  const meta = {
    ...blok.SEO,
    title: metaTitle,
    description: metaDescription,
    og_title: blok.SEO?.og_title || metaTitle,
    og_image: blok.SEO?.og_image || sharecardImage.src,
    og_description: blok.SEO?.og_description || metaDescription,
  };

  return (
    <>
      <Head>
        <link
          rel="canonical"
          href={sitePath(
            returnPathWithStartingSlash(
              additionalSEO?.cached_url || page.realPath || page.full_slug,
            ),
          )}
        />
        <title>{meta.title}</title>
        <meta property="og:title" content={meta.og_title} />
        <meta name="description" content={meta.description} />
        <meta property="og:description" content={meta.og_description} />
        <meta
          property="og:url"
          content={sitePath(returnPathWithStartingSlash(page.realPath || page.full_slug))}
        />
        <meta property="og:image" content={meta.og_image} />
        <meta name="robots" content={selectMetaRobots_forStoryblok(additionalSEO?.robots)} />
      </Head>
      <ExitPreviewMode preview={page.preview} currentSlug={page.full_slug} />
      <Layout type="engineers">
        <main {...storyblokEditable(blok)}>
          {blok.body?.map((nestedBlok) => (
            <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} page={page} />
          ))}
        </main>

        {/* Footer */}
        <StoryblokComponent
          key={footer.data.story.uuid}
          blok={footer.data.story.content}
          page={{
            ...page,
            footerCTA: { ...footerCTA, footerType: 'engineers', withCTA: blok.showFooterCTA },
          }}
        />
      </Layout>
    </>
  );
}
