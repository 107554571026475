import Layout from '@components/Layout';
import { selectMetaRobots_forStoryblok } from '@helpers/metaRobots';
import sitePath from '@helpers/sitePath';
import { returnPathWithStartingSlash } from '@helpers/storyblok.utils';
import { storyblokEditable, StoryblokComponent } from '@storyblok/react';
import getConfig from 'next/config';
import Head from 'next/head';
import sharecardImage from '@public/images/sharecard.jpg';
import type { DemandPageStoryblok, Storyblok } from '../types';
import { ExitPreviewMode } from '../ExitPreviewMode';

const { publicRuntimeConfig } = getConfig();
const { talentHubOnboardingUrl } = publicRuntimeConfig;

const footerCTA = {
  longText: false,
  text: 'Find the scale you need. Build your team today.',
  button: {
    url: talentHubOnboardingUrl,
    text: 'Build My Team',
  },
  image: '/images/home/businesses-footer.jpg',
};

export function DemandPage({ blok, page }: Storyblok<DemandPageStoryblok>) {
  const additionalSEO = blok.AdditionalSEO?.find((eachSEO) => eachSEO.component === 'seo');
  const metaTitle = [blok.SEO?.title || page.name, 'terminal.io'].join(' - ');
  const metaDescription = blok.SEO?.description;

  const { footer } = page.extra;

  const meta = {
    ...blok.SEO,
    title: metaTitle,
    description: metaDescription,
    og_title: blok.SEO?.og_title || metaTitle,
    og_image: blok.SEO?.og_image || sharecardImage.src,
    og_description: blok.SEO?.og_description || metaDescription,
  };

  return (
    <>
      <Head>
        <link
          rel="canonical"
          href={sitePath(
            returnPathWithStartingSlash(
              additionalSEO?.cached_url || page.realPath || page.full_slug,
            ),
          )}
        />
        <title>{meta.title}</title>
        <meta property="og:title" content={meta.og_title} />
        <meta name="description" content={meta.description} />
        <meta property="og:description" content={meta.og_description} />
        <meta
          property="og:url"
          content={sitePath(returnPathWithStartingSlash(page.realPath || page.full_slug))}
        />
        <meta property="og:image" content={meta.og_image} />
        <meta name="robots" content={selectMetaRobots_forStoryblok(additionalSEO?.robots)} />
      </Head>
      <ExitPreviewMode preview={page.preview} currentSlug={page.full_slug} />
      <Layout type="businesses">
        <main {...storyblokEditable(blok)}>
          {blok.body?.map((nestedBlok) => (
            <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} page={page} />
          ))}
        </main>
        {/* Footer */}
        <StoryblokComponent
          key={footer.data.story.uuid}
          blok={footer.data.story.content}
          page={{
            ...page,
            footerCTA: { ...footerCTA, footerType: 'businesses', withCTA: blok.showFooterCTA },
          }}
        />
      </Layout>
    </>
  );
}
