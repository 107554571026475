import * as Sentry from '@sentry/nextjs';
import { useState } from 'react';
import { useRouter } from 'next/router';
import * as Yup from 'yup';
import Cookies from 'js-cookie';
import { Formik, Form, Field, FormikErrors, FormikTouched } from 'formik';
import { storyblokEditable } from '@storyblok/react';

import {
  Box,
  FormControl,
  FormErrorMessage,
  Input,
  Checkbox,
  VStack,
  FormLabel,
  Heading,
  Container,
  Grid,
  GridItem,
} from '@terminal/design-system';

import { PrimaryButton } from '../../LightLP/Button';
import { useGetUtmCookies } from '../../../hooks/utmCookies';
import { generateStoryblokLinkUrl } from '../utils';
import { RichText } from '../primitive';
import { DownloadFormSectionStoryblok, Storyblok } from '../types';

const ContactSchema = {
  firstName: Yup.string()
    .min(1, 'Too Short!')
    .max(50, 'Too Long!')
    .trim()
    .required('First Name is required'),
  lastName: Yup.string()
    .min(1, 'Too Short!')
    .max(50, 'Too Long!')
    .trim()
    .required('Last Name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  jobTitle: Yup.string()
    .min(1, 'Too Short!')
    .max(50, 'Too Long!')
    .trim()
    .required('Job Title is required'),
  company: Yup.string()
    .min(1, 'Too Short!')
    .max(50, 'Too Long!')
    .trim()
    .required('Company is required'),
  subscribe: Yup.boolean(),
};

const FormInput = ({
  type,
  placeholder,
  labelText,
  showLabels,
  ...field
}: {
  type: string;
  showLabels: boolean;
  placeholder: string;
  labelText: string;
}) => {
  return (
    <>
      {showLabels && (
        <FormLabel fontWeight="bold" mb={1} color="text.inverse">
          {labelText}
        </FormLabel>
      )}
      <Input
        type={type}
        placeholder={showLabels ? '' : placeholder}
        borderColor="grey.400"
        borderRadius="0.25rem"
        bgColor="#fff"
        _placeholder={{ color: 'grey.500' }}
        focusBorderColor={'green.500'}
        {...field}
      />
    </>
  );
};

export function DownloadFormSection({ blok, page }: Storyblok<DownloadFormSectionStoryblok>) {
  const buttonTitle = blok.buttonTitle || 'Get Started';
  const title = blok.title;
  const showLabels = blok.showLabels;
  const formType = blok.formType;
  const redirectionLink = generateStoryblokLinkUrl(blok.redirectionLink);
  const file = blok.file?.filename;

  const FormSchema = Yup.object().shape(ContactSchema);
  const router = useRouter();
  const [submitError, setSubmitError] = useState<string>('');

  const utmCookies = useGetUtmCookies() ?? '';
  const utmParams = utmCookies ? '&' + utmCookies.replace(/\?/g, '') : '';

  return (
    <Box id="download" bgColor="grey.900" p="5rem 0 0" as="section" {...storyblokEditable(blok)}>
      <Container>
        <Grid gridTemplateColumns={{ lg: '1fr 1fr', xl: '4fr 5fr' }} gap={{ lg: '24' }}>
          <GridItem>
            {!title?.content || (title.content?.length === 1 && !title.content?.[0]?.content) ? (
              <Heading
                as="h2"
                fontSize={{ base: '2rem', lg: '4xl' }}
                lineHeight="shorter"
                mb={8}
                color="text.inverse"
              >
                Time to dig in. Download the report now and level up.
              </Heading>
            ) : (
              <RichText
                blok={{
                  ...blok,
                  component: 'richText',
                  content: title,
                }}
                key={blok._uid}
                page={page}
              />
            )}
          </GridItem>
          <GridItem>
            <Box px={4} pt={3} pb={12} maxW="430px" mx="auto">
              <Formik
                initialValues={{
                  firstName: '',
                  lastName: '',
                  email: '',
                  jobTitle: '',
                  company: '',
                  subscribe: false,
                }}
                validationSchema={FormSchema}
                onSubmit={async (values, actions) => {
                  try {
                    actions.setSubmitting(true);

                    await fetch('/api/team-builder', {
                      method: 'POST',
                      headers: {
                        'Content-Type': 'application/json',
                      },
                      body: JSON.stringify({
                        // form values
                        first_name: values.firstName,
                        last_name: values.lastName,
                        email: values.email,
                        title: values.jobTitle,
                        company: values.company,
                        subscribe: values.subscribe.toString(),
                        gclid: (router.query.gclid as string) ?? null,
                        // tracking values
                        fbc: Cookies.get('_fbc') ?? null,
                        fbp: Cookies.get('_fbp') ?? null,
                        form_type: formType,
                        utmParams,
                      }),
                    }).then((response) => {
                      if (response.ok) return response.json();
                      return Promise.reject(response);
                    });

                    if (file) window.open(file);

                    router.push(redirectionLink);
                  } catch (error: unknown) {
                    actions.setSubmitting(false);
                    setSubmitError('Form submission failed. Please, try again later.');
                    Sentry.captureException(error);
                  }
                }}
              >
                {({
                  errors,
                  touched,
                  isSubmitting,
                }: {
                  errors: FormikErrors<{
                    firstName: string;
                    lastName: string;
                    email: string;
                    jobTitle: string;
                    company: string;
                    subscribe: boolean;
                  }>;
                  touched: FormikTouched<{
                    firstName: string;
                    lastName: string;
                    email: string;
                    jobTitle: string;
                    company: string;
                    subscribe: boolean;
                  }>;
                  isSubmitting: boolean;
                }) => (
                  <Form>
                    <VStack spacing="4">
                      <Field name="firstName">
                        {({ field }) => (
                          <FormControl isInvalid={errors.firstName && touched.firstName}>
                            <FormInput
                              type="text"
                              placeholder="First Name"
                              labelText="First Name *"
                              showLabels={showLabels}
                              {...field}
                            />
                            <FormErrorMessage mt="px">{errors.firstName}</FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Field name="lastName">
                        {({ field }) => (
                          <FormControl isInvalid={errors.lastName && touched.lastName}>
                            <FormInput
                              type="text"
                              placeholder="Last Name"
                              labelText="Last Name *"
                              showLabels={showLabels}
                              {...field}
                            />
                            <FormErrorMessage mt="px">{errors.lastName}</FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Field name="email">
                        {({ field }) => (
                          <FormControl isInvalid={errors.email && touched.email}>
                            <FormInput
                              type="email"
                              placeholder="Email"
                              labelText="Work Email *"
                              showLabels={showLabels}
                              {...field}
                            />
                            <FormErrorMessage mt="px">{errors.email}</FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Field name="jobTitle">
                        {({ field }) => (
                          <FormControl isInvalid={errors.jobTitle && touched.jobTitle}>
                            <FormInput
                              type="text"
                              placeholder="Job Title"
                              labelText="Job Title *"
                              showLabels={showLabels}
                              {...field}
                            />
                            <FormErrorMessage mt="px">{errors.jobTitle}</FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Field name="company">
                        {({ field }) => (
                          <FormControl isInvalid={errors.company && touched.company}>
                            <FormInput
                              type="text"
                              placeholder="Company"
                              labelText="Company *"
                              showLabels={showLabels}
                              {...field}
                            />
                            <FormErrorMessage mt="px">{errors.company}</FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                    </VStack>
                    <Field name="subscribe">
                      {({ field }) => (
                        <FormControl mt="1rem">
                          <Checkbox {...field} colorScheme="white">
                            <Box
                              fontSize="sm"
                              lineHeight="1.25"
                              color="text.inverse"
                              fontWeight="bold"
                            >
                              I would like to receive communications from Terminal.
                            </Box>
                          </Checkbox>
                        </FormControl>
                      )}
                    </Field>
                    <PrimaryButton
                      mt={10}
                      w="full"
                      type="submit"
                      theme={{ green200: 'green.500' }}
                      isLoading={isSubmitting}
                      disabled={isSubmitting}
                      px={12}
                      onMouseDown={(event) => {
                        event.preventDefault();
                      }}
                      borderRadius="none"
                    >
                      {buttonTitle}
                    </PrimaryButton>
                    {submitError && (
                      <Box fontSize="xs" mt="1" color="red">
                        {submitError}
                      </Box>
                    )}
                  </Form>
                )}
              </Formik>
            </Box>
          </GridItem>
        </Grid>
      </Container>
    </Box>
  );
}
