export function ExitPreviewMode({
  preview,
  currentSlug,
}: {
  preview: boolean;
  currentSlug: string;
}) {
  if (!preview) return null;
  return (
    <a
      aria-label="Exit Preview Mode"
      title="Exit Preview Mode"
      href={`/api/storyblok/exit?slug=${currentSlug}`}
      style={{
        position: 'fixed',
        zIndex: 999999,
        background: 'red',
        color: 'white',
        padding: '5px 10px',
      }}
    >
      ⓧ
    </a>
  );
}
