import { useEffect, useState } from 'react';
import { getStoryblokApi } from '@storyblok/react';
import getConfig from 'next/config';
import Head from 'next/head';
import { storyblokEditable, StoryblokComponent } from '@storyblok/react';

import { selectMetaRobots_forStoryblok } from '@helpers/metaRobots';
import sitePath from '@helpers/sitePath';
import { returnPathWithStartingSlash } from '@helpers/storyblok.utils';

import { Box, Container, Heading, Text, Grid } from '@terminal/design-system';

import Layout from '@components/Layout';
import Hero from '@components/DemandLP/HeroDynamicSEO';
import Companies from '@components/DemandLP/Companies';
import sharecardImage from '@public/images/sharecard.jpg';
import { PrimaryButton } from '@components/UI/Button';
import { trackCtaClicked } from '@lib/segment/trackAnalytics';
import { CandidateCard } from '@components/UI/CandidateCard';
import { fiveCompaniesLogos } from '@helpers/constants';
import { CardWithIcon } from '@components/HiringMarketsLayout';
import globalImg from '@public/images/build-your-team/global-work.svg';
import ImageShimmer from '@components/ImageShimmer/ImageShimmer';
import { RolesPageStoryblok, Storyblok } from '../types';
import { SkillsList } from '../staticComponents';
import { GlobalComponent } from './GlobalComponent';
import { ExitPreviewMode } from '../ExitPreviewMode';

const { publicRuntimeConfig } = getConfig();
const { talentHubOnboardingUrl } = publicRuntimeConfig;

const benefits = [
  {
    image: {
      src: '/images/icons/icon-human-plus-blue.svg',
      alt: 'Guy inside of a circle',
    },
    title: 'Recruit',
    text: 'We continuously source engineers for core roles through inbound, outbound and referral sourcing',
  },
  {
    image: {
      src: '/images/icons/ic-target.svg',
      alt: 'Target with an arrow in the middle',
    },
    title: 'Match',
    text: 'Our talent experts and smart platform surface top candidates for your roles and culture.',
  },
  {
    image: {
      src: '/images/icons/ic-talk-yellow-heart.svg',
      alt: 'Chat with a white and yellow boxes with a yellow heart inside',
    },
    title: 'Interview',
    text: 'We collaborate to manage the interview and feedback process with you to ensure perfect fits.',
  },
  {
    image: {
      src: '/images/icons/ic-employ.svg',
      alt: 'Shaking hands',
    },
    title: 'Hire & Employ',
    text: 'We seamlessly hire and, if needed, manage remote employment, payroll, benefits, and equity.',
  },
];

const testimonial = {
  image: {
    sourceUrl: '/images/testimonials/weston-nielson.jpg',
    altText: 'Weston Nielson',
  },
  text: '“Terminal has been a great partner for us. They take a lot of the hassle out of recruiting while putting forward high quality candidates. We were able to make our first hire within weeks.”',
  author: {
    name: 'Weston Nielson',
    jobTitle: 'SVP of Engineering at Bluescape',
  },
};

export function RolesPage({ blok, page }: Storyblok<RolesPageStoryblok>) {
  // #region Refactor this please
  const [roleListStory, setRoleListStory] = useState(null);
  const storyblokApi = getStoryblokApi();

  useEffect(() => {
    if (!storyblokApi) return;
    async function getGlobalComponentRoleListWithWhitePills() {
      const { data } = await storyblokApi.get(
        `cdn/stories/private-global-components/roles-white-pills`,
        {
          version: 'published',
        },
      );
      setRoleListStory(data.story);
    }
    getGlobalComponentRoleListWithWhitePills();
  }, [storyblokApi]);
  // #endregion

  const roleName = page.name;

  const heroTitle = (
    blok.heroTitle || '**Hire %STORYNAME%** remotely from our vetted global talent'
  )
    .replace('%STORYNAME%', roleName)
    .replace('**', '<span style="color: #7AA550">')
    .replace('**', '</span>');

  const heroDescription = (
    blok.heroDescription ||
    "Terminal's vetted, elite global talent pool helps you hire %STORYNAME% 35% faster than traditional recruiting. Get instant access to elite %STORYNAME%, as we only accept the top 7% of developers."
  ).replace(/%STORYNAME%/g, roleName);

  const candidates =
    blok.candidates?.map((candidate) => {
      // TODO: improve this implementation
      const skills = [];
      const highlights = [];
      candidate.highlight1 && highlights.push(candidate.highlight1);
      candidate.highlight2 && highlights.push(candidate.highlight2);
      candidate.highlight3 && highlights.push(candidate.highlight3);
      candidate.highlight4 && highlights.push(candidate.highlight4);

      candidate.skill1 && skills.push(candidate.skill1);
      candidate.skill2 && skills.push(candidate.skill2);
      candidate.skill3 && skills.push(candidate.skill3);
      return {
        name: candidate.name,
        role: candidate.role,
        experience: candidate.experience,
        location: candidate.location,
        badges: candidate.badges,
        highlights,
        skills,
      };
    }) || [];

  const additionalSEO = blok.AdditionalSEO?.find((eachSEO) => eachSEO.component === 'seo');

  const metaTitle = [
    blok.SEO?.title || `Hire ${roleName} developers remotely 35% faster`,
    'terminal.io',
  ].join(' - ');
  const metaDescription =
    blok.SEO?.description ||
    `Hire ${roleName} from Terminal's vetted global talent pool. Build your remote engineering team 35% faster than traditional recruiting.`;

  const meta = {
    ...blok.SEO,
    title: metaTitle,
    description: metaDescription,
    og_title: blok.SEO?.og_title || metaTitle,
    og_image: blok.SEO?.og_image || sharecardImage.src,
    og_description: blok.SEO?.og_description || metaDescription,
  };

  const { footer } = page.extra;

  return (
    <>
      <Head>
        <link
          rel="canonical"
          href={sitePath(
            returnPathWithStartingSlash(
              additionalSEO?.cached_url || page.realPath || page.full_slug,
            ),
          )}
        />
        <title>{meta.title}</title>
        <meta property="og:title" content={meta.og_title} />
        <meta name="description" content={meta.description} />
        <meta property="og:description" content={meta.og_description} />
        <meta
          property="og:url"
          content={sitePath(returnPathWithStartingSlash(page.realPath || page.full_slug))}
        />
        <meta property="og:image" content={meta.og_image} />
        <meta name="robots" content={selectMetaRobots_forStoryblok(additionalSEO?.robots)} />
      </Head>
      <ExitPreviewMode preview={page.preview} currentSlug={page.full_slug} />
      <Layout type="businesses" getStartedLink={talentHubOnboardingUrl}>
        <main {...storyblokEditable(blok)}>
          {/* Hero section */}
          <Hero
            headingText={heroTitle}
            subHeadingText={heroDescription}
            heroUrlImg={globalImg}
            ctas={{
              primary: {
                url: talentHubOnboardingUrl,
                text: `Hire ${roleName}`,
              },
              secondary: {
                url: '/contact?v=2',
                text: 'Talk to Us',
              },
            }}
          />
          {/* Companies section */}
          <Box bgColor="grey.100" pb={{ base: 12, lg: 16 }} pt={{ base: 5, lg: 7 }} as="section">
            <Container>
              <Companies companies={fiveCompaniesLogos} columns={5} />
            </Container>
          </Box>
          {/* Candidates section */}
          <Box py={{ base: 12, lg: 16 }} as="section">
            <Container>
              {/** TODO: Use CandidateCardsSection Storyblok Component */}
              <Heading
                as="h2"
                variant="heading-2"
                fontSize="3xl"
                textAlign="center"
                textTransform="capitalize"
                mb={10}
              >
                Instant access to our top
                <Box as="span" color="green.500">
                  {` ${roleName}`}
                </Box>
              </Heading>
              <Grid
                templateColumns={{
                  md: 'repeat(2, 1fr)',
                  lg: 'repeat(3, 1fr)',
                }}
                gap={{ base: 4, lg: 6 }}
                mb={{ base: 6, lg: 10 }}
              >
                {candidates.map((candidate, index) => (
                  <CandidateCard
                    key={`${candidate.name}-${index}`}
                    candidate={candidate}
                    showCta={false}
                  />
                ))}
              </Grid>
              <PrimaryButton
                href={talentHubOnboardingUrl}
                w={{ base: '100%', md: '25rem' }}
                px={{ lg: 5 }}
                mx="auto"
                display="flex"
                onClick={() =>
                  trackCtaClicked({
                    link: talentHubOnboardingUrl,
                    name: `Hire ${roleName}`,
                    type: 'businesses',
                    context: `${roleName} role page`,
                  })
                }
              >
                Hire {roleName}
              </PrimaryButton>
            </Container>
          </Box>
          {/* Testimonials section */}
          <Box py={{ base: 12, lg: 16 }} bgColor="grey.900" as="section">
            <Container maxW="61.25rem">
              <Box
                pos="relative"
                overflow="hidden"
                borderRadius="full"
                mb={6}
                width={76}
                height={76}
              >
                <ImageShimmer
                  src={testimonial.image.sourceUrl}
                  alt={testimonial.image.altText}
                  width={76}
                  height={76}
                />
              </Box>
              <Heading
                as="h3"
                fontSize="xl"
                lineHeight="short"
                fontWeight="700"
                color="text.inverse"
                mb={6}
              >
                {testimonial.text}
              </Heading>
              <Text variant="label" fontWeight="bold" color="text.inverse">
                {testimonial.author.name}
              </Text>
              <Text variant="body" color="text.inverse">
                {testimonial.author.jobTitle}
              </Text>
            </Container>
          </Box>
          {/* How we hire section */}
          <Box py={{ base: 12, lg: 16 }} as="section">
            <Container>
              <Heading
                as="h2"
                variant="heading-2"
                fontSize="3xl"
                textAlign="center"
                mb={{ base: 7, lg: 10 }}
              >
                How we hire
                <Box as="span" color="green.500">
                  {` ${roleName} `}
                </Box>
                at Terminal
              </Heading>
              <Grid
                gridTemplateColumns={{
                  md: 'repeat(2, 1fr)',
                  lg: 'repeat(4, 1fr)',
                }}
                gap={10}
              >
                {benefits.map(({ title, image, text }) => (
                  <CardWithIcon
                    key={title}
                    title={title}
                    image={image}
                    text={text}
                    headingProps={{ fontSize: 'xl' }}
                  />
                ))}
              </Grid>
            </Container>
          </Box>
          {/* Skills links section */}
          <Box
            bgColor="bg.secondary"
            as="section"
            pt={{ base: 10, lg: 16 }}
            pos="relative"
            _after={{
              content: '""',
              position: 'absolute',
              width: '100%',
              height: '25rem',
              left: '0',
              top: '100%',
              bgColor: 'bg.secondary',
              zIndex: '-1',
            }}
          >
            <Container>
              <Heading variant="heading-2" as="h2" fontSize="3xl" textAlign="center" mb={2}>
                Find Developers By Role And Skill
              </Heading>
              <Text fontSize="lg" lineHeight="short" textAlign="center" mb={10}>
                Our software engineers and developers have the core skills you need.
              </Text>
              <Heading as="h3" variant="heading-3" color="text.secondary" mb={4}>
                Browse by Role
              </Heading>
              <GlobalComponent
                blok={{
                  ...blok,
                  story: roleListStory,
                  component: 'globalComponent',
                }}
                page={page}
              />
              <Heading as="h3" variant="heading-3" color="text.secondary" mt={10} mb={4}>
                Browse by Skill
              </Heading>
              <SkillsList
                blok={{
                  ...blok,
                  component: 'skillsList',
                  whitePill: true,
                }}
                page={page}
              />
            </Container>
          </Box>
          {blok.body?.map((nestedBlok) => (
            <StoryblokComponent page={page} blok={nestedBlok} key={nestedBlok._uid} />
          ))}
        </main>

        {/* Footer */}
        <StoryblokComponent
          key={footer.data.story.uuid}
          blok={footer.data.story.content}
          page={{
            ...page,
            footerCTA: {
              footerType: 'businesses',
              longText: false,
              text: 'Find the scale you need. Build your team today.',
              button: {
                url: talentHubOnboardingUrl,
                text: 'Build My Team',
              },
              image: '/images/home/businesses-footer.jpg',
              withCTA: true,
            },
          }}
        />
      </Layout>
    </>
  );
}
